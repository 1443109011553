<template>
  <div class="companies-logo-list-container">
    <img v-for="company in companies" :key="company.title" :src="company.logo" class="logo" :style="imgStyle" :alt="company.title + ' logo.'" />
  </div>
</template>

<script>
import CMA from '../assets/images/sign-in/cma.svg'
import natwest from '../assets/images/sign-in/nat-west.svg'
import lloyds from '../assets/images/sign-in/lloyds.svg'
import fundingCircle from '../assets/images/sign-in/funding-circle.svg'
import BNP from '../assets/images/sign-in/bnp.svg'

export default {
  name: 'CompaniesLogoList',
  props: {
    imgStyle: {
      type: Object
    }
  },
  data() {
    return {
      // List with all logos that should be shown on the component. The title is important as it is used to the alt and key properties.
      companies: [
        {
          title: 'CMA',
          logo: CMA
        },
        {
          title: 'NatWest',
          logo: natwest
        },
        {
          title: 'Lloyds',
          logo: lloyds
        },
        {
          title: 'Funding Circle',
          logo: fundingCircle
        },
        {
          title: 'BNP',
          logo: BNP
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.companies-logo-list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  gap: 10px;

  .logo {
    max-height: 30px;
    width: 100%;
  }
}
</style>
