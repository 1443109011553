<template>
  <div class="onboarding-left-panel">
    <div class="onboarding-left-panel-content">
      <SwoopLogo logoType="signup" class="onboarding-left-panel-logo"/>
      <h1 data-testid="onboarding-left-panel-title">{{ title }}</h1>
      <p data-testid="onboarding-left-panel-subtitle">{{ subtitle }}</p>
    </div>
    <footer class="footer-left-panel">
      <p>{{ footerText }}</p>
      <CompaniesLogoList v-if="showCompaniesLogoList"/>
      <PoweredBySwoop v-if="isPoweredBySwoop" class="powered-by-swoop"/>
    </footer>
  </div>
</template>

<script>
import CompaniesLogoList from '@/components/CompaniesLogoList.vue'
import SwoopLogo from '@/components/SwoopLogo'
import PoweredBySwoop from '@/components/PoweredBySwoop.vue'

export default {
  name: 'OnboardingLeftPanel',
  components: { CompaniesLogoList, SwoopLogo, PoweredBySwoop },
  data() {
    return {
      showCompaniesLogoList: this.$config.whitelabel.components.companiesLogoListEnabled,
      logo: require('../assets/images/swoop-logo-white-colour.svg'),
      isPoweredBySwoop: window?.WHITE_LABEL_STYLES?.subdomain !== 'swoop' && this.$config.whitelabel.components.poweredBySwoopEnabled
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    footerText: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables.scss';

.onboarding-left-panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  text-align: center;

  /deep/ .swoop-logo {
    min-width: 156px;
    max-width: 250px;
    max-height: 200px;
  }

  h1, p {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
  }

  .onboarding-left-panel-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    position: relative;
    height: 100%;
    padding: 0 28px;
    gap: 32px;

    .onboarding-left-panel-logo {
      margin-top: 55px;
    }

    h1 {
      margin: 0;
      font-size: 22px;
      letter-spacing: -0.13px;
      text-align: left;
      width: 300px;
      color: $color-lava-lamp-text;
    }

    p {
      margin: 0;
      font-size: 16px;
      letter-spacing: -0.27px;
      text-align: left;
      line-height: 25px;
      color: $color-lava-lamp-text;
      width: 300px;
    }
  }

  .footer-left-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    letter-spacing: -0.2px;
    text-transform: uppercase;
    text-align: center;
    line-height: 18px;
    color: $color-lava-lamp-text;
    width: 100%;
    margin: 20px 0;

    .powered-by-swoop {
      margin-top: 10px;
    }

    p {
      line-height: 24px;
      margin-bottom: 17px;
      font-size: 12px;
    }
  }
}
</style>
