<template>
  <div :class="{ 'is-modal': isModal }" class="sign-in-container">
    <AppOnboarding>
      <template v-if="!isModal" v-slot:left-panel>
        <OnboardingLeftPanel :title="$dictionary.signInLeftPanelTitle" :subtitle="$dictionary.signInLeftPanelSubtitle" :footerText="$dictionary.signInLeftPanelFooterText" />
      </template>

      <template v-slot:main-panel>
        <div class="sign-in-main" :class="{ 'is-modal': isModal }">
          <OnboardingHeader v-if="!isModal" :routerLink="getCreateAccountRoute" :tabIndex="5" />
          <ValidationObserver #default="{ validate }" class="validation-wrapper">
            <form class="sign-in-form" @submit.prevent="signInHandler(validate)">
              <p v-if="isModal" class="modal-warning">This email address is already registered with us, please sign into your account</p>
              <SwoopLogo class="swoop-logo" height="48px" v-if="!isIcccto" />
              <SwoopLogo class="swoop-logo margin-auto" height="90px" v-else />

              <h1 class="sign-in-title">Sign in</h1>

              <ValidationProvider tag="div" :rules="formValidations.email" name="email" class="sign-in-validation-provider" #default="{errors}">
                <label for="sign-in-email">Email</label>
                <input
                  id="sign-in-email"
                  type="email"
                  :class="{ 'sign-in-input-error': errors.length }"
                  placeholder="email@example.com"
                  v-model.trim="formInputs.userName"
                  autofocus
                  tabindex="1"
                />
                <FieldError v-bind="{ errors }" class="sign-in-field-error" />
              </ValidationProvider>

              <ValidationProvider tag="div" :rules="formValidations.password" name="password" class="sign-in-validation-provider" #default="{errors}">
                <div class="password-links">
                  <label for="sign-in-password">Password</label>
                  <router-link id="forgot-password" to="/forgot-password" tabindex="4">Forgot password?</router-link>
                </div>
                <input
                  id="sign-in-password"
                  :class="['input-password', { 'sign-in-input-error': errors.length }]"
                  type="password"
                  placeholder="●●●●●●●"
                  v-model="formInputs.password"
                  tabindex="2"
                />
                <FieldError v-bind="{ errors }" class="sign-in-field-error" />
              </ValidationProvider>
              <Button
                :buttonProps="{ id: 'sign-in-button', buttonText: 'Sign in', isLoading, loadingPercentage }"
                type="submit"
                class="sign-in-submit"
                data-testid="sign-in-submit-button"
                tabindex="3"
              />
            </form>
          </ValidationObserver>
        </div>
      </template>
    </AppOnboarding>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import { transitionRoutine } from '@/store/modules/transition/routines'
import { componentSizeRoutine } from '@/store/modules/geometricBackground/routines'
import { authGetters, loginRoutine } from '@/store/modules/auth/routines'
import { amplitudeEvents } from '@/utils/constants'
import { toastTopCenterOptions } from '@/config/vue-toast'
import store from '@/store'
import amplitudeTracking from '@/mixins/amplitudeTracking'
import deepLinking from '@/mixins/deepLinking'
import Button from '@/components/dynamicForms/Button.vue'
import FieldError from '@/components/forms/FieldError'
import AppOnboarding from '@/components/layout/AppOnboarding.vue'
import OnboardingLeftPanel from '@/components/OnboardingLeftPanel.vue'
import OnboardingHeader from '@/components/OnboardingHeader.vue'
import SwoopLogo from '@/components/SwoopLogo'
import { getWhitelabelNameFromSubdomain } from '@/utils/utils'

export default {
  name: 'signIn',
  mixins: [deepLinking, amplitudeTracking],
  components: { ValidationObserver, ValidationProvider, Button, FieldError, AppOnboarding, OnboardingLeftPanel, OnboardingHeader, SwoopLogo },
  props: {
    isModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      loadingPercentage: 0,
      formInputs: {
        userName: '',
        password: ''
      },
      formValidations: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true
        }
      },
      logo: require('@/assets/images/config/logo-mobile.svg')
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      isInvitedUser: authGetters.IS_INVITED_USER
    }),
    isIcccto() {
      return getWhitelabelNameFromSubdomain() === 'icccto'
    },
    getCreateAccountRoute() {
      const nextRoute = this.getNextDeeplinkRoute()
      if (nextRoute && ['get-started'].includes(nextRoute.name)) {
        return nextRoute
      }
      return this.$config.whitelabel.journey.defaultOnBoardingPage
    }
  },
  methods: {
    ...mapActions({
      login: loginRoutine.TRIGGER
    }),
    signInHandler(validate) {
      this.isLoading = true
      return validate()
        .then(async validationResult => {
          if (validationResult) {
            // Track sign in event on Amplitude Analytics.
            this.$ma.trackEvent(amplitudeEvents.fundingOnboarding.LOGIN)
            this.loadingPercentage = 70

            this.trackLloydsGrantFinderEvent(amplitudeEvents.lloydsSMEGrants.USER_LOGIN, 'Login')
            // Calling login vuex action.
            await this.login(this.formInputs)

            this.handleDeepLinkRedirect(this.isInvitedUser ? 'integrations' : 'matches-overview')
          }
          this.resetLoadingValues()
        })
        .catch(() => {
          this.resetLoadingValues()
          this.$toasted.show('Invalid login details', {
            ...toastTopCenterOptions,
            type: 'error',
            icon: 'error_outline'
          })
        })
    },
    resetLoadingValues() {
      this.isLoading = false
      this.loadingPercentage = 0
    }
  },
  created() {
    // eslint-disable-next-line camelcase
    if (this.$route.query?.password_created) {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.passwordCreated
      })
    }
    // Gets the parameters on the current URL if any.
    const queryParams = Object.keys(this.$route.query).reduce((acc, key) => {
      const value = this.$route.query[key]
      acc[key] = Array.isArray(value) ? value[0] : value
      return acc
    }, {})
    if (queryParams?.email) {
      // Autofill email input if it is available on the query parameters.
      this.formInputs.userName = queryParams.email
    }
    if (this.$route.params.email) {
      // Autofill email input if it is available on the route parameters.
      this.formInputs.userName = this.$route.params.email
    }
    if (queryParams?.success) {
      this.$toasted.show('Password successfully changed.', { ...toastTopCenterOptions, className: 'toasted-success' })
    }
    // Removes parameters off of the route.
    if (!queryParams?.deepLinkAction) {
      this.$router.replace({ query: {} }).catch(e => e)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  beforeMount() {
    // Setting correct slide transition and geometric background size for this route.
    store.dispatch(transitionRoutine.TRIGGER, 'slide-left')
    store.dispatch(componentSizeRoutine.TRIGGER, 'one-quarter-width')
  },
  mounted() {
    // eslint-disable-next-line camelcase
    if (window?.WHITE_LABEL_STYLES?.logo !== undefined) {
      this.logo = window.WHITE_LABEL_STYLES.logo
    }
    this.trackLloydsGrantFinderEvent(amplitudeEvents.pageViews.LLOYDS_BANK_GRANT_FINDER, 'Login')
  },
  unmounted() {
    this.loadingPercentage = 0
  },
  watch: {
    user(newUser) {
      // If the user is updated (successfully signed in), the loading percentage is 100%.
      if (newUser) {
        this.loadingPercentage = 100
      }
    }
  },
  metaInfo() {
    return {
      link: [{ rel: 'canonical', href: `${process.env.VUE_APP_PROD_URL}login` }]
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.is-modal {
  display: flex;
  justify-content: center;
  max-height: 700px;
  /deep/ .app-onboarding-container {
    display: flex;
    justify-content: center;
    max-height: 700px;
    max-width: 900px;
    padding: 16px;
  }
  /deep/ .app-onboarding-container .left-panel {
    display: none;
  }

  .modal-warning {
    position: absolute;
    text-align: center;
    top: -100px;
    width: 390px;
    background-color: var(--color-error-200);
    border-radius: 10px;
    padding: 5px;
  }
  @media only screen and (max-width: 500px) {
    .modal-warning {
      width: 85%;
    }
  }
}
.sign-in-container {
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  line-height: 19px;
  letter-spacing: -0.4px;
  /deep/ .app-onboarding-container .left-panel {
    width: 25vw;
    z-index: 1;
  }
  /deep/ .app-onboarding-container .main-panel {
    width: 75vw;
    display: flex;
    .sign-in-main .onboarding-header {
      display: flex;
      flex: none;
      align-items: center;
      align-self: flex-end;
    }
  }
  .sign-in-main {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    .validation-wrapper {
      max-width: calc(100vw - 48px);
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
    .sign-in-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: relative;
      gap: 32px;
      /deep/ .custom-button {
        width: 100%;
      }
      .sign-in-validation-provider {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 600;
        font-size: 16px;
        .sign-in-input-error {
          border: 1px solid var(--color-error-500);
        }
      }
      .swoop-logo {
        display: none;
        margin: 0;
      }

      .margin-auto {
        margin: auto;
      }

      .sign-in-field-error {
        font-weight: 500;
        margin-top: 10px;
        color: var(--color-error-500);
      }
      .password-links {
        display: flex;
        width: 390px;
        max-width: calc(100vw - 48px);
        align-items: center;
        justify-content: space-between;
        label {
          font-size: 16px;
          line-height: 22px;
        }
        a {
          margin-bottom: 7px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
          color: $color-inactive;
          letter-spacing: -0.4px;
        }
      }
      img {
        display: none;
      }
      h1 {
        color: $color-signin-header;
        line-height: 32px;
        margin: 0;
      }
      label {
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 19px;
        color: $color-primary;
      }
      input {
        height: 56px;
        width: 390px;
        max-width: calc(100vw - 48px);
        box-sizing: border-box;
        border: 1px solid var(--color-secondary-50);
        border-radius: 8px;
        background-color: $color-white;
        color: var(--color-primary-500);
      }
      /deep/ .sign-in-submit {
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: $breakpoint-xlg) {
    /deep/ .app-onboarding-container .left-panel {
      width: 35vw;
    }
    /deep/ .app-onboarding-container .main-panel {
      width: 65vw;
    }
  }
  @media only screen and (max-width: $breakpoint-md) {
    /deep/ .app-onboarding-container .left-panel {
      width: 0;
      visibility: hidden;
    }
    /deep/ .app-onboarding-container .main-panel {
      width: 100vw;
    }
    /deep/ .onboarding-header {
      margin-top: 24px;
    }
    .sign-in-main .sign-in-form {
      gap: 16px;
      padding: 24px;
      img {
        display: block;
        width: 170px;
        max-width: 170px;
        margin-bottom: 34px;
      }
      h1 {
        margin-bottom: 24px;
      }
      /deep/ .sign-in-submit {
        width: 110px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .sign-in-main .sign-in-form {
      .sign-in-title {
        display: none;
      }
      .swoop-logo {
        display: inline;
      }
    }
  }
}
</style>
