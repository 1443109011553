<template>
  <PoweredBySwoopImage />
</template>

<script>
import PoweredBySwoopImage from '@/assets/images/powered_by_swoop.svg?inline'

export default {
  name: 'PoweredBySwoop',
  components: { PoweredBySwoopImage }
}

</script>
<style scoped lang="scss">
  @import '../assets/styles/swoop/variables.scss';

  .powered-by-swoop {
    fill: $color-powered-by-swoop;
  }
</style>
