<template>
  <header class="onboarding-header">
    <span>{{ spanText }}</span>
    <router-link :to="routerLink" tag="button" :tabindex="tabIndex" :style="buttonStyle">
      {{ buttonText }}
    </router-link>
  </header>
</template>

<script>
export default {
  name: 'OnboardingHeader',
  props: {
    tabIndex: {
      type: Number,
      default: 1
    },
    spanText: {
      type: String,
      default: "Don't have an account?"
    },
    buttonText: {
      type: String,
      default: 'Create account'
    },
    amplitudeEvent: {
      type: String,
      default: ''
    },
    routerLink: {
      type: Object
    },
    buttonStyle: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/swoop/variables.scss';
.onboarding-header {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px;
  span {
    margin-right: 17px;
    font-size: 14px;
    text-decoration: none;
    color: $color-inactive;
  }
  button {
    font-family: 'Public Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 117px;
    height: 32px;
    box-sizing: border-box;
    border: 1px solid var(--color-secondary-50);
    border-radius: 10px;
    background-color: transparent;
    color: $color-general-headers;
    &:hover {
      cursor: pointer;
      color: $color-primary;
      font-weight: bold;
    }
  }
}
@media only screen and (max-width: $breakpoint-md) {
  .onboarding-header span {
    display: none;
  }
  .onboarding-header {
    button {
      width: 155px;
    }
  }
}
</style>
