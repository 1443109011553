import { render, staticRenderFns } from "./PoweredBySwoop.vue?vue&type=template&id=804e7ee6&scoped=true&"
import script from "./PoweredBySwoop.vue?vue&type=script&lang=js&"
export * from "./PoweredBySwoop.vue?vue&type=script&lang=js&"
import style0 from "./PoweredBySwoop.vue?vue&type=style&index=0&id=804e7ee6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "804e7ee6",
  null
  
)

export default component.exports